import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'

import { OutlineButton, theme } from '../util/style'
import { Flex, Box } from '@rebass/grid'
import PageHeroGallery from '../components/page-hero-gallery'
import PageCtaBoxes from '../components/page-cta-boxes'
import PageRangeGrid from '../components/page-range-grid'
import Title from '../components/page-title'
import Row from '../components/page-content-row'
import Overview from '../components/page-overview'
import SubGrey from '../components/page-subtitle-grey'
import HalfGridRow from '../components/half-grid-row'
import PageSubtitle from '../components/page-subtitle'
import PageDescription from '../components/page-description'
import PageHeading from '../components/page-heading'
import HouseSeriesRow from '../components/house-series-row'
import HouseSeriesRowLinkItem from '../components/house-series-row/house-series-row-link-item'
import HalfGridRowItem from '../components/half-grid-row/half-grid-row-item'
import HausSeriesLink1Svg from '../components/svg/haus-series-link/haus-series-1'
import HausSeries2 from '../components/svg/haus-series-link/haus-series-2'

import HeroVideo from '../video/happyhaus-3840x2160-2024-02-15-video-tarragindi-4861_web_small.mp4'

import HausSeries3 from '../components/svg/haus-series-link/haus-series-3'
import IconRightArrow from '../components/svg/icon-right-arrow'
import BlogRelated from '../components/blog-page/PageBlog/BlogRelated'
import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'

import ArrowText from '../components/v2/ArrowText'
import styled from 'styled-components'
import BlogCopyRow from '../components/blog-page/PageBlog/BlogCopyRow'

// import Hehappyhaus-hero-1.jpgroVideo1 from '../video/happyhaus-video-homepage-split-logo.mp4'

const HeroThumb1 = 'home/happyhaus-hero-2022-08-16-render-tarragindi-001.png'
const HeroThumb2 = 'home/happyhaus-gallery-photo-ocean-shores-JG-1090042.jpg'
const HeroThumb3 = 'home/happyhaus-gallery-photo-dewar-AM-3.jpg'

const ogImages = [
  `https://happyhaus.com.au/${HeroThumb1}`,
  `https://happyhaus.com.au/${HeroThumb2}`,
  `https://happyhaus.com.au/${HeroThumb3}`,
]

class Page extends Component {
  render() {
    const { data } = this.props
    const homesData = data.allHomesJson.edges

    const slides = [
      {
        format: 'video',
        image: HeroThumb1,
        video: HeroVideo,
        tileTitle: ``,
        tileCopy: ``,
        link: '/',
        playbackId: 'hZiB8o8Vlq2a421sR501upR2SMpwE023jux26iqqMZWfc',
      },
    ]

    return (
      <>
        <SEO
          title="Considered living"
          description="A Happy Haus is beautiful yet practical. It is a home that
                  engages with its surrounds, and maximises the ability to enjoy
                  the benefits of life in the subtropics."
          images={ogImages}
        />
        <PageHeroGallery
          slides={slides}
          hasCopy={true}
          ratio={720 / 1240}
          noMarginBottom
        />
        <Row>
          <Flex flexWrap={['wrap', 'nowrap']} pt={['24px', '29px']}>
            <Box width={[1, 6 / 12]} px={2}>
              <PageHeading>Design + Build</PageHeading>
            </Box>
            <Box width={[1, 6 / 12]} px={2} mr={[0, 0]}>
              <PageDescription align="left">
                Our design + build practice draws from the best in architecture
                and construction. A Happy Haus is a home that connects to its
                surroundings and elevates how you live in the subtropics.
                <ArrowText to="/philosophy" mt={['40px']}>
                  Our Philosophy
                </ArrowText>
              </PageDescription>
            </Box>
          </Flex>
          <BlogRelated
            data={{
              relatedPosts: [
                {
                  slug: 'top-sales-results-in-new-farm-and-tarragindi',
                  title: 'Top sales results in New Farm and Tarragindi',
                  category: ['Insight'],
                  thumbnail: {
                    file: {
                      path: '/images/custom-haus/happyhaus-gallery-2024-02-02-photo-james-1100859.jpg',
                    },
                  },
                },
                {
                  title: 'Making more neighbourhood memories',
                  slug: 'silex-haus-a-legacy-of-memories',
                  category: ['Owners Club'],
                  thumbnail: {
                    file: {
                      path: '/images/custom-haus/happyhaus-gallery-2023-03-15-photo-silex-JG-1010415.jpg',
                    },
                  },
                },
              ],
            }}
          />
          <HouseSeriesRow mt={['115px', '143px']} noCopy>
            <HouseSeriesRowLinkItem
              heading="Haus Series"
              number="01"
              textColor="#3957A5"
              bgColor="#B3DFFF"
              link="/haus-series-1"
              svg={<HausSeriesLink1Svg />}
            />
            <HouseSeriesRowLinkItem
              heading="Haus Series"
              number="02"
              textColor="#FF634D"
              bgColor="#FFCDB2"
              link="/haus-series-2"
              svg={<HausSeries2 />}
            />
            <HouseSeriesRowLinkItem
              heading="Haus Series"
              number="03"
              textColor="#3E6F42"
              bgColor="#C4D39B"
              link="/haus-series-3"
              svg={<HausSeries3 />}
            />
            <HouseSeriesRowLinkItem
              heading={
                <>
                  Collection <br />
                  <b>Courtyards</b>
                </>
              }
              number={<Box style={{ opacity: 0 }}>.</Box>}
              textColor="#4B4B4B"
              bgColor="#CDCDC8"
              link="/courtyards"
            />
          </HouseSeriesRow>
        </Row>
        <BlogContainer pb={['43px', '50px']} pt={['83px', '50px']}>
          <BlogCopyRow
            data={{
              video: {
                playbackId: '9gUpQwC4XAawbDbGFeJBjdhImT1BH01pjBzGfiEF4xz8',
              },
              copyHtml: (
                <div>
                  <p>
                    <Link to="/gallery-new-farm-haus-107/">New Farm</Link> is a
                    four-storey, four-bedroom, three-and-a- half-bathroom home
                    that maximises a smaller-than-average 300sqm block. We are
                    thrilled with this outcome for our client, our design +
                    build team and the lucky new Happy Haus owners.
                  </p>
                  <Link
                    to="/gallery-new-farm-haus-107"
                    style={{ borderBottom: 0 }}
                  >
                    <Button>View Gallery</Button>
                  </Link>
                </div>
              ),
            }}
          />
        </BlogContainer>
      </>
    )
  }
}

export default Page

export const query = graphql`
  query {
    allHomesJson {
      edges {
        node {
          id
          storey
          title
          stats {
            bed
            bath
            car
            price
            size
          }
          summary
          thumb
        }
      }
    }
  }
`

/*
<PageBreakout>
				A Happy Haus is beautiful yet practical. It is a home that engages with its surrounds, and maximises the ability to enjoy the benefits of life in the subtropics, inside and out. Considered living by Happy Haus.
				</PageBreakout>
				*/

const Button = styled(OutlineButton)``
